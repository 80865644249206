<template>
  <a-modal
    title="设置推荐视频"
    width='50%'
    :visible="visible"
    :confirmLoading="confirmLoading"
    :destroyOnClose="true"
    @ok="handleAuditOne"
    @cancel="handleCancel"
  >
    <a-form :form="form">
      <!-- 父级 -->
      <a-form-item label="视频推荐位" :labelCol="labelCol" :wrapperCol="wrapperCol" v-if="positions.length">
        <a-select v-model="recommendPositionId" placeholder="选择视频推荐位" style="width: 150px; margin-right: 15px">
          <a-select-option :value="position.id" v-for="position in positions" :key="position.id">
            {{ position.position }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="推荐到期时间" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-range-picker allow-clear v-model="timeRange" show-time style="max-width: 560px" format="YYYY-MM-DD hh:mm" />
      </a-form-item>
      <a-form-item label="排序" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input-number
          style="width: 200px"
          v-decorator="['sort', { rules: [{ required: true, message: '请输入！' }] }]"
          placeholder="请输入"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { recommendPosition } from '@/api/modular/mallLiving/videoClass'
import { editRecommendVideo } from '@/api/modular/mallLiving/recommendVideo'
import moment from 'moment'
export default {
  data() {
    return {
      visible: false, //modal框显示状态
      confirmLoading: false,
      oneData: {}, //父组件传过来的checkResult和id
      form: this.$form.createForm(this),
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      endTime: '',
      beginTime: '',
      recommendPositionId: undefined,
      timeRange: [],
      positions: []
    }
  },
  created() {
    this.getList()
  },
  methods: {
    //初始化方法
    one(data) {
      this.recommendPositionId = data.position
      this.visible = true
      this.oneData = data
      setTimeout(() => {
        this.form.setFieldsValue({
          sort: data.sort
        })
        this.timeRange = []
        this.timeRange.push(moment(data.beginTime))
        this.timeRange.push(moment(data.endTime))
      }, 300)
    },
    getList() {
      recommendPosition().then(res => {
        console.log('推荐', res)
        if (res.success) {
          this.positions = res.data
        }
      })
    },
    // 点击遮罩层或右上角叉或取消按钮的操作
    handleCancel() {
      this.recommendPositionId = undefined

      this.visible = false
      this.form.setFieldsValue({
        sort: 100
      })
    },
    onChange(date) {
      var date = new Date(date).getTime()
      return date
    },
    handleAuditOne() {
      this.form.validateFields((errors, values) => {
        if (!errors) {
          if (!this.timeRange) {
            this.$message.error('请选择推荐时间')
            return
          } else if (this.onChange(this.timeRange[1]._d) < new Date().getTime()) {
            this.$message.error('到期结束时间不能早于当前时间')
            return
          }
          editRecommendVideo({
            videoId: this.oneData.videoId,
            recommendPositionId: this.recommendPositionId,
            sort: values.sort,
            id: this.oneData.id,
            endTime: this.onChange(this.timeRange[1]._d),
            beginTime: this.onChange(this.timeRange[0]._d)
          }).then(res => {
            if (res.success) {
              this.$message.success('编辑成功')
              this.$emit('ok')
              this.handleCancel()
            } else {
              this.$message.error('操作失败')
              setTimeout(() => {
                this.handleCancel()
              }, 3000)
            }
          })
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.mytable {
  margin-bottom: 70px;
  border-collapse: collapse;
  width: 100%;
  height: 250px;

  .title {
    background: rgb(207, 248, 248);
    width: 20%;
  }

  td {
    border: 2px solid rgb(228, 225, 225);
    padding-left: 7px;
    font-size: 15px;
  }
}

img {
  width: 100px;
  height: 50px;
}

.flex {
  display: flex;
}

.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
